/*Open Sans*/
@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSansBold.eot');
  src: local('Open Sans Bold'), local('Open-Sans-Bold'),
  url('../assets/fonts/OpenSansBold.eot') format('embedded-opentype'),
  url('../assets/fonts/OpenSansBold.woff2') format('woff2'),
  url('../assets/fonts/OpenSansBold.woff') format('woff'),
  url('../assets/fonts/OpenSansBold.ttf') format('truetype'),
  url('../assets/fonts/OpenSansBold.svg#OpenSansBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSansSemiBold.eot');
  src: local('Open Sans Semi Bold'), local('Open-Sans-Semi-Bold'),
  url('../assets/fonts/OpenSansSemiBold.eot') format('embedded-opentype'),
  url('../assets/fonts/OpenSansSemiBold.woff2') format('woff2'),
  url('../assets/fonts/OpenSansSemiBold.woff') format('woff'),
  url('../assets/fonts/OpenSansSemiBold.ttf') format('truetype'),
  url('../assets/fonts/OpenSansSemiBold.svg#OpenSansSemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSansMedium.eot');
  src: local('Open Sans Medium'), local('Open-Sans-Medium'),
  url('../assets/fonts/OpenSansMedium.eot') format('embedded-opentype'),
  url('../assets/fonts/OpenSansMedium.woff2') format('woff2'),
  url('../assets/fonts/OpenSansMedium.woff') format('woff'),
  url('../assets/fonts/OpenSansMedium.ttf') format('truetype'),
  url('../assets/fonts/OpenSansMedium.svg#OpenSansMedium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSansRegular.eot');
  src: local('Open Sans Regular'), local('Open-Sans-Regular'),
  url('../assets/fonts/OpenSansRegular.eot') format('embedded-opentype'),
  url('../assets/fonts/OpenSansRegular.woff2') format('woff2'),
  url('../assets/fonts/OpenSansRegular.woff') format('woff'),
  url('../assets/fonts/OpenSansRegular.ttf') format('truetype'),
  url('../assets/fonts/OpenSansRegular.svg#OpenSansRegular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/*Montserrat*/
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/MontserratExtraBold.eot');
  src: url('../assets/fonts/MontserratExtraBold.eot') format('embedded-opentype'),
  url('../assets/fonts/MontserratExtraBold.woff2') format('woff2'),
  url('../assets/fonts/MontserratExtraBold.woff') format('woff'),
  url('../assets/fonts/MontserratExtraBold.ttf') format('truetype'),
  url('../assets/fonts/MontserratExtraBold.svg#MontserratExtraBold') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/MontserratBold.eot');
  src: url('../assets/fonts/MontserratBold.eot') format('embedded-opentype'),
  url('../assets/fonts/MontserratBold.woff2') format('woff2'),
  url('../assets/fonts/MontserratBold.woff') format('woff'),
  url('../assets/fonts/MontserratBold.ttf') format('truetype'),
  url('../assets/fonts/MontserratBold.svg#MontserratBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/MontserratSemiBold.eot');
  src: url('../assets/fonts/MontserratSemiBold.eot') format('embedded-opentype'),
  url('../assets/fonts/MontserratSemiBold.woff2') format('woff2'),
  url('../assets/fonts/MontserratSemiBold.woff') format('woff'),
  url('../assets/fonts/MontserratSemiBold.ttf') format('truetype'),
  url('../assets/fonts/MontserratSemiBold.svg#MontserratSemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/MontserratMedium.eot');
  src: url('../assets/fonts/MontserratMedium.eot') format('embedded-opentype'),
  url('../assets/fonts/MontserratMedium.woff2') format('woff2'),
  url('../assets/fonts/MontserratMedium.woff') format('woff'),
  url('../assets/fonts/MontserratMedium.ttf') format('truetype'),
  url('../assets/fonts/MontserratMedium.svg#MontserratMedium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/MontserratRegular.eot');
  src: url('../assets/fonts/MontserratRegular.eot') format('embedded-opentype'),
  url('../assets/fonts/MontserratRegular.woff2') format('woff2'),
  url('../assets/fonts/MontserratRegular.woff') format('woff'),
  url('../assets/fonts/MontserratRegular.ttf') format('truetype'),
  url('../assets/fonts/MontserratRegular.svg#MontserratRegular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
