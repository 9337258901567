@import "../../../scss/mixin";

.lang-toggler {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  color: var(--color-text1);
}

.lang-toggler__current {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  height: 40px;
  border-radius: 8px;
  @include trCubicFast();
  color: var(--color-btn-link__text);
  background-color: var(--color-btn-link__bg);
  svg {
    flex-shrink: 0;
    margin-left: 8px;
  }
}

.lang-toggler__menu-list {
  position: absolute;
  z-index: 1;
  top: 100%;
  right: 0;
  width: 160px;
  height: auto;

  &--current {
    color: var(--color-btn-hover);
  }
}
[home-slide="2"] {
  &.dark {
    .lang-toggler__current {
      color: #312f2f;
      &:hover,
      &:focus {
        color: var(--color-btn-link__text--hover);
        background-color: var(--color-btn-link__bg--hover);
      }
      &:active {
        color: var(--color-btn-link__text--pressed);
        background-color: var(--color-btn-link__bg--pressed);
      }
    }
  }
}

[home-slide="4"] {
  .lang-toggler__current {
    color: #ffffff;
    background-color: transparent;
    &:hover,
    &:focus {
      color: var(--color-btn-link__text--hover);
      background-color: var(--color-btn-link__bg--hover);
    }
    &:active {
      color: var(--color-btn-link__text--pressed);
      background-color: var(--color-btn-link__bg--pressed);
    }
  }
}

@media screen and (max-width: 1140px) {
  .lang-toggler__current {
    padding: 0 8px;
  }
}

@media screen and (max-width: 1024px) {
  .lang-toggler__menu-list {
    bottom: 100%;
    top: auto;
    right: 0;
    margin-bottom: 5px;
    transform-origin: bottom right !important;
  }

  .lang-toggler__current {
    padding: 0 6px;

    svg {
      margin-left: 0px;
    }
  }
}
