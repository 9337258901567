@import "../../../scss/mixin";

.header-mobile {
  color: var(--color-text1);
  background-color: var(--color-bg3);
  box-shadow: 0px 11px 27px rgba(0, 0, 0, 0.17), 0px 2px 8px rgba(0, 0, 0, 0.14),
    0px 1.12694px 2.75474px rgba(0, 0, 0, 0.08);
}

.header-mobile__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
}

.header-mobile__logo {
  svg {
    display: block;
    width: 68px;
    height: 16px;
  }
}

.header-mobile__nav {
  margin-right: 24px;
}

.header-mobile__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-mobile__lang-toggler {
  margin-right: 24px;
}

.header-mobile__theme-toggler {
  margin-right: 24px;
}

.header-mobile__btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: -8px;
}

.header-mobile__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  @include trCubic();
  &:hover {
    color: var(--color-btn-active);
    background-color: var(--color-btn-icon);
  }
  &:active {
    color: var(--color-text5);
    background-color: var(--color-btn-active);
  }
}

@media screen and (max-width: 768px) {
  .header-mobile__nav {
    margin-right: 16px;
  }

  .header-mobile__lang-toggler {
    margin-right: 16px;
  }

  .header-mobile__theme-toggler {
    margin-right: 16px;
  }
}
