@import "../../../../scss/mixin";

.header-nav {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  >li + li {
    margin-left: 24px;
  }
}

.header-nav__item {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 16px 6px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-text1);
  border-bottom: 3px solid transparent;
  @include trCubicFast();
  &.active {
    border-bottom: 3px solid #4E4D4B;
  }
  &:hover {
    border-bottom: 3px solid #4E4D4B;
  }
  svg {
    @include trCubicFast();
    margin-left: 4px;
  }
}

.dark {
  .header-nav__item {
    &.active {
      border-bottom: 3px solid #FAE100;
    }
    &:hover {
      border-bottom: 3px solid #FAE100;
    }
  }
}


.header-nav__menu {
  position: relative;
}

.header-nav__menu-list {
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
  width: auto;
  height: auto;
  transform-origin: top center!important;
  white-space: nowrap;
}

@media screen and (max-width: 1140px){
  .header-nav {
    >li + li {
      margin-left: 16px;
    }
  }

  .header-nav__item {
    padding: 8px 8px;
  }
}
