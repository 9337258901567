@import "scss/fonts.scss";
@import "scss/anim.scss";
@import "scss/mixin";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-text1: #4e4d4b;
    --color-text2: #2d2b2a;
    --color-text3: #6d6d6d;
    --color-text4: #a9abae;
    --color-text5: #ffffff;
    --color-title1: #fab300;
    --color-title2: #f19000;
    --color-main-title: #000000;
    --color-btn-bg: #fae100;
    --color-btn-text: #4e4d4b;
    --color-btn-hover: #fab300;
    --color-btn-active: #f19000;
    --color-btn-arrow: #ebebeb;
    --color-btn-icon: #fefcdd;
    --color-market-border: #f2f2f2;
    --color-expander-bg: #f2f2f2;
    --color-expander-arrow: #e0e0e0;
    --color-diagram-bg: #ffffff;
    --color-footer-bg: #f2f2f2;
    --color-footer-title: #a9abae;
    --color-footer-text: #4e4d4b;
    --color-error: #e42208;
    --color-uahg-bg1: #eff1f3;
    --color-uahg-bg2: #eff1f3;
    --color-uahg-bg3: #fafafa;
    --color-uahg-bg4: #ffffff;
    --color-bg3: #ffffff;

    --color-btn-link__text: #4e4d4b;
    --color-btn-link__bg: transparent;
    --color-btn-link__text--hover: #d96e00;
    --color-btn-link__bg--hover: #fffcdd;
    --color-btn-link__text--pressed: #ffffff;
    --color-btn-link__bg--pressed: #f19000;

    --color-home-slide-bg: #ffffff;
    --color-home-slide-text: #312f2f;
    --color-home-slide-lines: #f2f2f2;

    --color-patners-lines: #f2f2f2;
    --color-patners-label1: #a45504;
    --color-patners-label2: #fffcdd;
    --color-patners-bg1: #ffffff;
    --color-patners-bg2: #fafafa;
    --color-patners-card1: #ecfce9;

    &.dark {
      --color-text1: #f2f2f2;
      --color-text2: #f2f2f2;
      --color-text3: #f2f2f2;
      --color-text4: #a9abae;
      --color-text5: #2d2b2a;
      --color-title1: #ffcd1d;
      --color-title2: #ffcd1d;
      --color-main-title: #f2f2f2;
      --color-btn-bg: #fae100;
      --color-btn-text: #000000;
      --color-btn-hover: #fab300;
      --color-btn-active: #f19000;
      --color-btn-arrow: #ebebeb;
      --color-btn-icon: #4e4d4b;
      --color-market-border: #6d6d6d;

      --color-expander-bg: #4e4d4b;
      --color-expander-arrow: #6d6d6d;
      --color-diagram-bg: #6d6d6d;
      --color-footer-bg: #2d2b2a;
      --color-footer-title: #6d6d6d;
      --color-footer-text: #f2f2f2;
      --color-error: #e42208;
      --color-uahg-bg1: #3b3d3f;
      --color-uahg-bg2: #46484a;
      --color-uahg-bg3: #312f2f;
      --color-uahg-bg4: #46484a;
      --color-bg3: #2d2b2a;

      --color-btn-link__text: #ffffff;
      --color-btn-link__bg: transparent;
      --color-btn-link__text--hover: #ffffff;
      --color-btn-link__bg--hover: #4e4d4b;
      --color-btn-link__text--pressed: #ffffff;
      --color-btn-link__bg--pressed: #fab300;

      --color-home-slide-bg: #2d2b2a;
      --color-home-slide-text: #f2f2f2;
      --color-home-slide-lines: #4e4d4b;

      --color-patners-lines: #e5e5e5;
      --color-patners-label1: #fffcdd;
      --color-patners-label2: transparent;
      --color-patners-bg1: #4e4d4b;
      --color-patners-bg2: #2d2b2a;
      --color-patners-card1: #0d5933;
    }
  }
}

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  font-family: "Open Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-bg3);

  &.body--fixed {
    position: static;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  height: 100%;

  &__top {
    position: fixed;
    z-index: 20;
    width: 100%;
    flex-shrink: 0;
  }

  &__content {
    flex: 1 0 auto;
    padding-top: 88px;
  }

  &__bottom {
    width: 100%;
    flex-shrink: 0;
  }

  &__bottom-fixed {
    position: fixed;
    z-index: 20;
    bottom: 0;
    width: 100%;
    flex-shrink: 0;
  }
}

.app__wrapper {
  max-width: 1264px;
  margin: 0 auto;
  padding: 0 16px;
  box-sizing: border-box;
}

.app__wrapper-small {
  max-width: 912px;
  margin: 0 auto;
}

.app__link {
  @include trCubicFast();
  cursor: pointer;

  &:hover,
  &:active {
    color: var(--color-title2);
  }
}

.app__link--underline {
  @include trCubicFast();
  color: var(--color-title2);
  cursor: pointer;

  &:hover,
  &:active {
    text-decoration: underline;
  }
}

.app__btn {
  &--link {
    color: var(--color-btn-link__text);
    background-color: var(--color-btn-link__bg);

    &:hover {
      color: var(--color-btn-link__text--hover);
      background-color: var(--color-btn-link__bg--hover);
    }

    &:active {
      color: var(--color-btn-link__text--pressed);
      background-color: var(--color-btn-link__bg--pressed);
    }
  }
}

.app__btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  height: 48px;
  border-radius: 8px;
  background-color: var(--color-btn-bg);

  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.01em;
  color: var(--color-btn-text);
  @include trCubicFast();

  &:hover {
    background-color: var(--color-btn-hover);
  }

  &:active {
    background-color: var(--color-btn-active);
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.6;
  }
}

.app__btn--outline {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  height: 48px;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-btn-bg);
  background-color: transparent;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.01em;
  color: var(--color-text1);
  @include trCubicFast();

  &:hover {
    background-color: var(--color-btn-hover);
    border: 2px solid var(--color-btn-hover);
    color: var(--color-btn-text);
  }

  &:active {
    background-color: var(--color-btn-active);
    border: 2px solid var(--color-btn-active);
    color: var(--color-btn-text);
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.6;
  }
}

.app__menu {
  background: var(--color-bg3);
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  margin: 4px 0;
  @include trCubicFast();
  transform: scale(0.5);
  transform-origin: top right;
  box-shadow: 0px 11px 27px rgba(0, 0, 0, 0.17), 0px 2px 8px rgba(0, 0, 0, 0.14),
    0px 1.12px 2.75px rgba(0, 0, 0, 0.08);

  &--active {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }

  &--top {
    transform-origin: bottom right;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    button,
    a {
      display: inline-flex;
      align-items: center;
      height: 48px;
      width: 100%;
      padding: 0 24px;
      text-align: left;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;

      &.active {
        color: var(--color-btn-hover);
      }

      &:hover {
        //color: var(--color-text1);
        color: #4e4d4b;
        background-color: var(--color-btn-bg);
      }
    }
  }
}

.app-theme--anim {
  * {
    transition-delay: 0s !important;
    transition-property: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .app-main {
    &__content {
      padding-top: 0;
    }
  }

  .fc-widget-normal {
    bottom: 55px !important;
  }

  .fc-widget-open {
    height: auto !important;
    overflow: auto !important;
    position: inherit !important;
    width: auto !important;
  }
}
