@import "../../../../scss/mixin";

.sidebar {
  &--active {
    .sidebar__overlay {
      opacity: .6;
      visibility: visible;
    }
    .sidebar__aside {
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
    }
  }
}

.sidebar__overlay {
  position: fixed;
  z-index: 25;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-bg3);
  opacity: 0;
  visibility: hidden;
  @include trCubic();
}

.sidebar__aside {
  position: fixed;
  z-index: 30;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 312px;
  bottom: 0;
  padding: 20px 0 40px;
  opacity: 0;
  visibility: hidden;
  color: var(--color-text1);
  background-color: var(--color-bg3);
  box-shadow: 5px 4px 12px rgba(0, 0, 0, 0.51);
  @include trCubic();
  transform: translateX(calc(100% + 24px));
}

.sidebar__head {
  position: relative;
  padding: 0 40px 20px;
  border-bottom: 1px solid #EBEBEB;
}

.sidebar__close {
  position: absolute;
  top: 0px;
  right: 20px;
  color: var(--color-text1);
  cursor: pointer;
  @include trCubicFast();
  &:hover, &:active {
    color: var(--color-btn-hover);
  }
}

.sidebar__body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.sidebar__content {
  flex-grow: 1;
}




.sidebar__actions {
  padding: 48px 40px;
  .sidebar__btn {
    width: 100%;
    height: 40px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    &--sign {
      svg {
        margin-right: 8px;
      }
    }
    &--create {
      margin-top: 30px;
      svg {
        margin-right: 8px;
      }
    }
  }
}

.sidebar__footer {
  flex-shrink: 0;
}

.sidebar__footer-title {
  margin-bottom: 16px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-text3);
}

.sidebar__footer-links {
  display: flex;
  justify-content: center;
  align-items: center;
  li + li {
    margin-left: 24px;
  }
  a {
    color: var(--color-text4);
  }
}
