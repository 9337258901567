[data-a] {
  transition-duration: .6s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(.25,.46,.45,.94);
}

[data-a-delay="1200"].a-animate {
  transition-delay: 1.2s;
}

[data-a-delay="1000"].a-animate {
  transition-delay: 1s;
}

[data-a-delay="800"].a-animate {
  transition-delay: .8s;
}

[data-a-delay="600"].a-animate {
  transition-delay: .6s;
}

[data-a-delay="400"].a-animate {
  transition-delay: .4s;
}

[data-a-delay="200"].a-animate {
  transition-delay: .2s;
}


[data-a^=zoom][data-a^=zoom].a-animate {
  opacity: 1;
  transform: translateZ(0) scale(1);
}

[data-aos=zoom-in-up] {
  transform: translate3d(0,100px,0) scale(.6);
}

[data-a=zoom-in] {
  transform: scale(.6);
}

[data-a^=zoom] {
  opacity: 0;
}

[data-a^=fade][data-a^=fade].a-animate {
  opacity: 1;
  transform: translateZ(0);
}

[data-a^=fade] {
  opacity: 0;
}

[data-a=fade-up] {
  transform: translate3d(0, 100px, 0);
}

[data-a=fade-right] {
  transform: translate3d(-100px, 0, 0);
}

[data-a=fade-left] {
  transform: translate3d(100px, 0, 0);
}
