@import "../../../scss/mixin";

.theme-toggler {
  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    @include trCubic();
  }
}

[home-slide="2"] {
  &.dark {
    .theme-toggler__btn {
      color: #312f2f;
      &:hover {
        color: var(--color-btn-link__text--hover);
        background-color: var(--color-btn-link__bg--hover);
      }
      &:active {
        color: var(--color-btn-link__text--pressed);
        background-color: var(--color-btn-link__bg--pressed);
      }
    }
  }
}

[home-slide="4"] {
  .theme-toggler__btn {
    color: #ffffff;
    background-color: transparent;
    &:hover {
      color: var(--color-btn-link__text--hover);
      background-color: var(--color-btn-link__bg--hover);
    }
    &:active {
      color: var(--color-btn-link__text--pressed);
      background-color: var(--color-btn-link__bg--pressed);
    }
  }
}
