.footer {
  background-color: var(--color-footer-bg);;
}

.footer__grid {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding-top: 72px;
  padding-bottom: 80px;
}

.footer__left {
  display: flex;
  flex-direction: column;
  width: 33.33%;
  padding-right: 64px;
}

.footer__logo {
  color: #4E4D4B;
}


.footer__right {
  display: flex;
  justify-content: space-between;
  width: 66.66%;
}

.footer__right-item {
  margin-left: 16px;

  ul {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-footer-text);
  }

  li {
    max-width: 155px;
  }

  li + li {
    margin-top: 24px;
  }

  a {
    display: inline-flex;
    align-items: center;
  }

  svg {
    margin-right: 16px;
  }
}

.footer__title {
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.01em;
  color: var(--color-footer-title);
}

@media screen and (max-width: 1024px){
  .footer__grid {
    flex-direction: column;
    padding-top: 46px;
    padding-bottom: 48px;
  }

  .footer__left {
    width: 100%;
    padding-bottom: 48px;
    padding-right: 0;
  }

  .footer__right {
    width: 100%;
  }

  .footer__right-item {
    margin-left: 0;
    ul {
      margin-bottom: 36px;
    }
  }
}


@media screen and (max-width: 768px){
  .footer {
    padding: 0 8px;
  }

  .footer__grid {
    padding-top: 48px;
  }
}


@media screen and (max-width: 640px){
  .footer__right {
    flex-wrap: wrap;
  }

  .footer__right-item {
    width: 50%;

  }
}
