@import "../../../scss/mixin";

.loader {
  position: fixed;
  z-index: 25;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-bg3);
  color: var(--color-text1);
  opacity: 0;
  visibility: hidden;
  @include trLinearSlow();
  &--active {
    opacity: 1;
    visibility: visible;
    .loader__content {
      svg {
        transform: translateY(0px);
      }
    }
  }
}

.loader__content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  svg {
    width: 233px;
    height: 74px;
    transform: translateY(100px);
    @include trLinearSlow();
  }
}
