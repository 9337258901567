@import "../../../../scss/mixin";

.header-nav-mobile {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}

.header-nav-mobile__item {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-text1);
  height: 40px;
  border-radius: 4px;
  @include trCubicFast();

  &:hover {
    color: var(--color-btn-active);
    background-color: var(--color-btn-icon);
  }

}

.header-nav-mobile__menu {
  position: relative;

}

.header-nav-mobile__menu-list {
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 0;
  width: auto;
  height: auto;
  margin-bottom: 5px;
  transform-origin: bottom center!important;
  white-space: nowrap;
}

.header-nav-mobile__menu-item--border {
  border-top: 1px solid #D5D6D8;
}

